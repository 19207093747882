/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7e9553eb-d401-4d7b-88fa-0d98c646e093",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2AD4aPWxt",
    "aws_user_pools_web_client_id": "qb21alkd7h6dqvj5vs3tqfdv0",
    "oauth": {},
    "aws_user_files_s3_bucket": "pate20213723ed06531948b6a5a0b14d1c3fb499175248-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
